<template>
    <div class="timeline-events">
        <zw-event-card v-for="(event,index) in sortedEvents"
                       :key="'active_'+index"
                       :event="event"
                       :callback="callback"
                       :index="index"
                       v-if="isActiveTodo(event)"
                       :customerId="customerId"
                       :customerForm="customerForm"
        />

        <b-alert variant="info"
                 v-if="events.filter(isActiveTodo).length === 0"
                 show
        >
            <font-awesome-icon icon="info-circle" class="mr-2"></font-awesome-icon>
            No Active Todos to display
        </b-alert>

        <hr>
        <zw-event-card v-for="(event,index) in events"
                       :key="index"
                       :event="event"
                       :callback="callback"
                       :index="index"
                       :customerId="customerId"
                       :customerForm="customerForm"
                       v-if="!isActiveTodo(event)"
        />

        <b-alert variant="info"
                 v-if="events.filter(event => !isActiveTodo(event)).length === 0"
                 show
        >
            <font-awesome-icon icon="info-circle" class="mr-2"></font-awesome-icon>
            No Events to display
        </b-alert>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import ZwEventCard from "@/components/ZwEventCard.vue";

export default {
    name: 'zw-events-new',
    components: {ZwEventCard},
    props: {
        'events': {
            type: Array,
            default: [],
        },
        'customerId': null,
        'customerForm': null,
        'show-events': {
            type: Array,
            default: () => {
                return []
            }
        },
        'callback': null,
    },
    data() {
        return {}
    },
    methods: {
        isActiveTodo(event) {
            if (event.event == 'customer_todo' && event.data.status == 'todo') {
                // if (moment(event.created_at).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')) {
                    return true
                // }
            }

            return false
        },
    },
    computed: {
        sortedEvents() {
            return [...this.events].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        },
    },
}
</script>
<style lang="scss">
.timeline-events {
    .event-done {
        text-decoration: line-through
    }

    .event-content div p {
        margin-bottom: 0px !important;
    }

    .event-content.closed {
        max-height: 33px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
    }

    .event-content.closed::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 15px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        pointer-events: none;
    }

    .card-body {
        padding: 0.5rem;
    }

    .card {
        margin-bottom: 0.5rem;
    }

    .card-warning {
        background-color: #ff9966;

        .event-content.closed::after {
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 153, 102, 1));
        }
    }
}
</style>