<template>
    <div class="pt-2">
        <b-row>
            <b-col cols="3">
                <validation-observer tag="div" ref="observer">
                    <b-card style="position: sticky;top: 0px;">
                        <b-row>
                            <b-col>
                                <zw-select-group
                                    v-model="form.customer_status"
                                    :options="customerStatuses"
                                    name="customer_status"
                                    :label-prefix="labelPrefix"
                                    size="s"
                                ></zw-select-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col v-for="field in form.customFields"
                                   :key="field.name"
                                   cols="12"
                                   v-if="showField(field)"
                            >
                                <custom-field v-model="form.customValues[field.name]"
                                              :model="form"
                                              :minDate="field.options.actual_dates == 1 ? currentDate() : ''"
                                              :readonly="field.options.readonly == 1 ? true : false"
                                              :isRequired="field.options.required == 1 ? true : false"
                                              :field="field"
                                />
                            </b-col>
                        </b-row>
                    </b-card>
                </validation-observer>
                <div class="form-actions" v-if="formChanged">
                    <b-row style="position: fixed;width: 25%;">
                        <b-col>
                            <b-button variant="primary" @click="onSubmit" block>{{
                                    $t('common.button.save')
                                }}
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col cols="9">
                <zw-events-new :events="getEvents()"
                               :callback="shown"
                               :customerId="customerId"
                               :customerForm="form"
                ></zw-events-new>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ValidateMixin from "@/mixins/validate";
import ZwEventsNew from "@/components/ZwEventsNew";
import moment from "moment/moment";

export default {
    name: 'customer-events',
    components: {ZwEventsNew},
    mixins: [ValidateMixin, commonSave],
    props: {
        customerId: {
            type: Number,
        },
        sidebar: {}
    },
    data() {
        return {
            loading: false,
            labelPrefix: 'customer.label.',
            defaultForm: {},
            form: {},
            formChanged: false,
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('CommonData', ['getEntityStatuses']),
        ...mapGetters('Customer', ['getCustomer', 'getEvents', 'getEventsCustomerData']),
        shown() {
            const eventsFetch = this.$store.dispatch('Customer/fetchEvents', this.customerId)
            this.$store.dispatch('Customer/fetchCustomer', this.customerId)
                .then(() => {
                    this.form = JSON.parse(JSON.stringify({...this.defaultForm, ...this.getCustomer()}))

                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                    this.formChanged = false
                })
        },
        currentDate() {
            const today = moment();
            return today.format('YYYY-MM-DD');
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    let formData = JSON.parse(JSON.stringify(this.form))

                    this.$store.dispatch('Customer/saveCustomer', formData)
                        .then((response) => {
                            this.shown()
                        })
                        .catch(errors => {
                            if (errors) {
                                this.loading = false
                                this.$refs['observer'].setErrors(errors)
                            }
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        showField(field, customGroup) {
            if (!field.options.show_in_activity) {
                return false
            }

            let onlyFor = field.options.only_for || []
            let onlyForIncluded = onlyFor.find(item => {
                return this.form.customerType.includes(item)
            })

            return field.options.group != 'hidden' && (onlyFor.length == 0 || (onlyFor.length && onlyForIncluded))
        },
    },
    computed: {
        customerStatuses() {
            const customerStatuses = this.getEntityStatuses()['customer'] || []
            let list = {}
            customerStatuses.map(val => {
                list[val.id] = val.name
            })

            return list;
        }
    },
    watch: {
        'form': {
            handler: function () {
                this.formChanged = true
            },
            deep: true
        }
    },
}
</script>